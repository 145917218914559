const nativeCallback = (callback, json) => {
  if (window.JSBridge) {
    // Android webview
    if (callback in window.JSBridge) {
      if (!!json) {
        window.JSBridge[callback](JSON.stringify(json));
      } else {
        window.JSBridge[callback]();
      }
      return true;
    }
  } else if (window.webkit && "messageHandlers" in window.webkit && callback in window.webkit.messageHandlers) {
    window.webkit.messageHandlers[callback].postMessage(json);
    return true;
  }

  console.error(`Unregistered callback: "${callback}"`);
  return false;
};

export { nativeCallback };
