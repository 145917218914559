import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  choices = null;

  connect() {
    this.choices = new Choices(this.element, {
      shouldSort: false,
      allowHTML: false,
      searchResultLimit: 10,
      fuseOptions: {
        threshold: 0.0,
        ignoreLocation: true,
      },
      classNames: {
        containerInner: "form-select",
        input: "form-control",
        item: "choices__item",
        highlightedState: "bg-selected",
        selectedState: "bg-selected",
      },
    });
  }

  disconnect() {
    // Choices instance should be destroyed, but this code hangs the browser (Brave)
    // this.choices.destroy();
  }
}
