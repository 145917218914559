import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-collection"
export default class extends Controller {
  static targets = [ "checkbox" ]

  connect() {}

  toggleAll(e) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked
    });
  }

  selectByIds(e) {
    e.preventDefault();
    const ids = JSON.parse(e.target.dataset.ids);
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = ids.includes(checkbox.value)
    });
  }
}
