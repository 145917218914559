import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { fetchHeaders } from "../helpers/fetch_helper";

export default class extends Controller {
  connect() {
    Sortable.create(this.element, {
      animation: 150,
      handle: ".drag-handle",
      group: this.element.dataset.sortableGroup,
      onEnd: (event) => {
        this.updatePositions(event);
      },
    });
  }

  updatePositions(event) {
    if (event.from == event.to && event.newIndex == event.oldIndex) return;

    var updateUrl = event.item.dataset.update_url;
    var parentId = event.to.dataset.id;
    var newIndex = event.newIndex;
    var className = event.item.dataset.class_name;
    var body = { [className]: { position: newIndex, parent_id: parentId } };

    fetch(updateUrl, {
      method: "PUT",
      headers: fetchHeaders(),
      body: JSON.stringify(body),
      credentials: "same-origin",
    }).catch((error) => {
      alert("Error updating: " + error);
    }).then((response) => {
      if (response.ok) {
        document.location.reload();
      }
    })
  }

  disconnect() {}
}
