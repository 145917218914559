// Implements bulk actions on view bulk actions

import { Controller } from "@hotwired/stimulus";
import { fetchHeaders } from "../helpers/fetch_helper";

export default class extends Controller {
  static targets = ["href"];
  bulkActionsName = "bulk-actions";

  connect() {}

  click(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    dispatchEvent(new CustomEvent("message:addWaiting"));

    if (!!e.target.dataset.confirm && !confirm(e.target.dataset.confirm))
      return;

    fetch(e.target.href, {
      method: e.target.dataset.method || "post",
      headers: fetchHeaders(),
      credentials: "same-origin",
      body: JSON.stringify({
        ids: this.bulkActionsController("field").selectedIds(),
      }),
    })
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        // https://esbuild.github.io/content-types/#direct-eval
        // This requires :unsafe_eval to be enabled in the Content Security Policy
        // It would be nice if we could use a more secure method
        (0, eval)(text);
      })
      .then(() => {
        dispatchEvent(new CustomEvent("message:removeWaiting"));
        dispatchEvent(new CustomEvent("bulkActions:render"));
      })
      .catch((error) => {
        alert("Error updating: " + error);
      });
  }

  bulkActionsController() {
    // Find parent bulk-actions controller
    var element = this.element.closest(
      `[data-controller=${this.bulkActionsName}]`
    );
    if (!!element) {
      return this.application.getControllerForElementAndIdentifier(
        element,
        this.bulkActionsName
      );
    }
  }
}
