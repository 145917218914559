import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="password-field-toggle"
export default class extends Controller {
  static targets = ["field", "label"];

  connect() {
    this.prepareFieldTarget();
    this.addIcon();
  }

  addIcon() {
    this.fieldTarget.insertAdjacentHTML(
      "afterend",
      `<span class="form-control-label" \
        data-${this.identifier}-target="label" \
        data-action="click->${this.identifier}#togglePasswordVisibility">${I18n.password_field_toggle_show}</span>`
    );
  }

  togglePasswordVisibility() {
    if (this.fieldTarget.type == "password") {
      this.fieldTarget.type = "text";
      this.labelTarget.innerHTML = I18n.password_field_toggle_hide;
    } else {
      this.fieldTarget.type = "password";
      this.labelTarget.innerHTML = I18n.password_field_toggle_show;
    }
    this.fieldTarget.focus();
  }

  // private

  prepareFieldTarget() {
    var field = this.element.getElementsByTagName("input")[0];
    field.setAttribute(`data-${this.identifier}-target`, "field");
    field.classList.add("with-label");
  }
}
