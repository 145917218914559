import { Controller } from "@hotwired/stimulus";
import { nativeCallback } from "../helpers/native_callback_helper";

export default class extends Controller {
  connect() {
    const callback = this.data.get("callback");
    if (!!callback) {
      nativeCallback(callback, null);
    }
  }

  closeWebView(e) {
    if (!!e) e.preventDefault();

    var response = nativeCallback("closeWebView", null);
    if (!response) {
      if (document.referrer == "") {
        window.close();
      } else {
        history.back();
      }
    }
  }

  startOauthLogin(e) {
    if (!!e) e.preventDefault();

    var response = nativeCallback("startOauthLogin", null);
    if (!response) {
      console.log(
        "Could not call native method `startOauthLogin`, redirecting to login screen."
      );
      window.location = "/access/users/sign_in";
    }
  }
}
