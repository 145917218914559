// Helper for dirty checks on a form
import { Controller } from "@hotwired/stimulus";
import autosize from "autosize";
import { debounce } from "../helpers/debounce";

export default class extends Controller {
  static targets = ["submit", "field"];
  fields = null;
  submitsWithHtml = '<i class="fa fa-spinner fa-spin"/>';
  submitsWithPlain = "...";

  connect() {
    this.reset();
    this.bindFields();
    this.addEventListeners();
    this.updateSubmitState();

    autosize(this.element.querySelectorAll("textarea"));
    this.element.noValidate = true;
    this.clearServerSideValidationOnFirstChange();
    this.spinnerOnSubmit();
    this.disableKeyboardSubmitOnMultipleButtons();
  }

  disconnect() {
    this.removeEventListeners();
  }

  submit(e) {
    if (!!e) e.preventDefault();

    this.submitTarget.click();
  }

  submitWithDebounce(e) {
    if (!!e) e.preventDefault();

    debounce((e) => {
      this.submit(e);
    })();
  }

  hasChange() {
    if (this.fields == null) return false;

    var fieldHash = this.formFieldHash();
    for (var key in fieldHash) {
      if (
        !key ||
        key == "authenticity_token" ||
        typeof this.fields[key] == "undefined"
      )
        continue;

      if (this.fields[key] !== fieldHash[key]) {
        return true;
      }
    }

    return false;
  }

  clearFieldsToSkipDirtyCheck() {
    this.fields = null;
  }

  formFieldHash() {
    var result = {};
    this.formFields().forEach((field) => {
      // Skip unselected radio buttons
      if (field.type == "radio" && !field.checked) return;

      result[field.name] = field.value;
    });
    return result;
  }

  formFields() {
    return this.element.querySelectorAll("input, textarea, select, password");
  }

  reset() {
    this.fields = this.formFieldHash();
  }

  refresh() {
    this.element.querySelector("#refresh_only").value = "1";
    storeScrollPosition();
    this.submit();
  }

  reload() {
    this.element.querySelector("#reload_only").value = "1";
    storeScrollPosition();
    this.submit();
  }

  confirmCloseMessage() {
    return this.element.dataset["confirmClose"];
  }

  addEventListeners() {
    addEventListener("beforeunload", this, false);
    addEventListener("turbo:before-visit", this, false);
    addEventListener(`form:${this.element.id}:formLoaded`, this, false);
    this.element.addEventListener("submit", this, false);
  }

  removeEventListeners() {
    removeEventListener("beforeunload", this, false);
    removeEventListener("turbo:before-visit", this, false);
    removeEventListener(`form:${this.element.id}:formLoaded`, this, false);
  }

  hideModal(e) {
    if (e.namespace === "bs.modal") {
      if (this.hasChange()) {
        if (confirm(this.confirmCloseMessage())) {
          this.reset();
        } else {
          e.preventDefault();
        }
      }
    }
  }

  bindFields() {
    this.formFields().forEach((field) => {
      field.addEventListener("change", this, false);
      field.addEventListener("input", this, false);
    });
    this.element.addEventListener("change", this, false);
  }

  fieldChanged(_field) {
    this.updateSubmitState();
  }

  dimElement() {
    if (this.data.has("dim-element-on-submit")) {
      document
        .querySelector(this.data.get("dim-element-on-submit"))
        .classList.add("dimmed");
    }
  }

  updateSubmitState() {
    if (!this.hasSubmitTarget) return;

    if (this.requiredFieldsFilled() && this.formNotInvalid()) {
      this.submitTargets.forEach((submit) => {
        submit.classList.remove("disabled");
      });
    } else {
      this.submitTargets.forEach((submit) => {
        submit.classList.add("disabled");
      });
    }
  }

  requiredFieldsFilled() {
    // This may be redundant. By default, on fields required in ActiveModel
    // only a `required` class will be added to the label. Adding `required: true`
    // to the `f.*_field both sets the class for the label and the `required`
    // attribute on the input field.
    var fieldNodes = this.element.querySelectorAll("[required]");
    var labelNodes = this.element.querySelectorAll("label.required");

    return !(
      Array.from(fieldNodes).some((field) => {
        return (
          (field.type == "checkbox" && !field.checked) ||
          (field.type == "radio" &&
            !Array.from(
              this.element.querySelectorAll(`[name="${field.name}"]`)
            ).some((field) => field.checked)) ||
          field.value == ""
        );
      }) ||
      Array.from(labelNodes).some((label) => {
        var elem = document.getElementById(label.htmlFor);
        return (
          !!elem &&
          ((elem.type == "checkbox" && !elem.checked) || elem.value == "")
        );
      })
    );
  }

  formNotInvalid() {
    return this.element.querySelectorAll(".is-invalid").length === 0;
  }

  clearInvalid(field) {
    if (field.type == "radio") {
      Array.from(
        this.element.querySelectorAll(`[name="${field.name}"]`)
      ).forEach((field) => {
        field.classList.remove("is-invalid");
      });
    } else {
      field.classList.remove("is-invalid");
    }
  }

  clearServerSideValidationOnFirstChange() {
    this.element.querySelectorAll(".is-invalid").forEach((field) => {
      field.addEventListener(
        "input",
        (e) => {
          this.clearInvalid(e.target);
        },
        { once: true }
      );
    });
  }

  spinnerOnSubmit() {
    this.submitTargets.forEach((element) => {
      if (element.offsetWidth == 0) {
        return;
      }

      element.style.width = `${element.offsetWidth + 2}px`;
      // element.style.height = `${element.offsetHeight}px`;
      element.dataset.turboSubmitsWith =
        element.tagName == "BUTTON"
          ? this.submitsWithHtml
          : this.submitsWithPlain;
    });
  }

  disableKeyboardSubmitOnMultipleButtons() {
    if (this.submitTargets.length <= 1) return;

    this.formFields().forEach((element) => {
      element.setAttribute("enterkeyhint", "done");
      element.addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
          event.target.blur();
        }
      });
    });
  }

  handleEvent(e) {
    switch (e.type) {
      case "beforeunload":
        var confirmationMessage = this.confirmCloseMessage();
        if (!!confirmationMessage && this.hasChange()) {
          (e || window.event).returnValue = confirmationMessage; //Gecko + IE
          return confirmationMessage; //Webkit, Safari, Chrome etc.
        }
        break;
      case "turbo:before-visit":
        var confirmationMessage = this.confirmCloseMessage();
        if (!!confirmationMessage && this.hasChange()) {
          if (!confirm(confirmationMessage)) {
            e.preventDefault();
          }
        }
        break;
      case `form:${this.element.id}:formLoaded`:
        this.bindFields();
        break;
      case "change":
        this.fieldChanged(e.target);
        break;
      case "input":
        this.fieldChanged(e.target);
        break;
      case "submit":
        this.clearFieldsToSkipDirtyCheck();
        break;
    }
  }
}
