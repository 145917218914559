import { Controller } from "@hotwired/stimulus";
import { debounce } from "../helpers/debounce";

// Connects to data-controller="validate-email"
export default class extends Controller {
  static targets = ["field", "feedback"];

  connect() {
    this.prepareFieldTarget();
    this.addFeedback();
    this.fieldTarget.addEventListener("input", this, false);
    this.fieldTarget.addEventListener("blur", this, false);
    if (!this.fieldTarget.classList.contains("is-invalid")) {
      this.fetchFeedback();
    }
  }

  addFeedback() {
    var feedback = this.element.getElementsByClassName("invalid-feedback")[0];
    if (!!feedback) {
      feedback.setAttribute(`data-${this.identifier}-target`, "feedback");
    } else {
      this.fieldTarget.insertAdjacentHTML(
        "afterend",
        `<div class="invalid-feedback" data-${this.identifier}-target="feedback"></div>`
      );
    }
  }

  handleEvent(e) {
    switch (e.type) {
      case "input":
      case "blur":
        this.fetchFeedback();
        break;
    }
  }

  // private

  fetchFeedback() {
    // Don't update feedback if field has default value
    if (this.fieldTarget.value == this.fieldTarget.defaultValue) return;

    if (this.fieldTarget.value != "") {
      const params = { email: this.fieldTarget.value };
      const urlParams = new URLSearchParams(params).toString();
      var url = `/v1/valid_email?${urlParams}`;

      debounce(() => {
        fetch(url).then((response) => {
          if (response.ok) {
            this.fieldTarget.classList.remove("is-invalid");
            this.feedbackTarget.innerHTML = "";
          } else {
            this.fieldTarget.classList.add("is-invalid");
            if (document.activeElement !== this.fieldTarget)
              this.feedbackTarget.innerHTML = I18n.validation_email_not_valid;
          }

          // Notify parent form a change may have occured
          this.fieldTarget.form.dispatchEvent(new CustomEvent("change"));
        });
      })();
    } else {
      if (this.fieldTarget.required) {
        this.fieldTarget.classList.add("is-invalid");
      } else {
        this.fieldTarget.classList.remove("is-invalid");
      }
      this.feedbackTarget.innerHTML = "";
    }
  }

  prepareFieldTarget() {
    var field = this.element.getElementsByTagName("input")[0];
    field.setAttribute(`data-${this.identifier}-target`, "field");
  }
}
