import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  connect() {
    var previous_path = window.location.pathname;
    var new_path = new URL(this.urlValue, window.location.href).pathname;

    window.location.href = this.urlValue;

    if (previous_path == new_path) {
      window.location.reload(this.urlValue);
    }
  }
}
