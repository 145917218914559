import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["street", "city", "postcode", "houseNumber", "invalidPostcode"];

  connect() {
    if (this.hasInvalidPostcodeTarget) this.postcodeCheck();
  }

  disconnect() {}

  postcodeCheck() {
    if (
      !this.data.has("postcode-checks-url") ||
      !this.postcodeTarget.value.trim() ||
      !this.houseNumberTarget.value.trim()
    ) {
      this.invalidPostcodeTarget.classList.remove("is-invalid");
      return;
    }

    var url = `${this.data.get("postcode-checks-url")}?postcode=${this.postcodeTarget.value}&house_number=${
      this.houseNumberTarget.value
    }`;

    fetch(url, { headers: { Authorization: `Bearer ${this.data.get("user-token")}` } })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            this.streetTarget.value = json.street;
            this.cityTarget.value = json.city;
          });
          this.invalidPostcodeTarget.classList.remove("is-invalid");
        } else {
          this.invalidPostcodeTarget.classList.add("is-invalid");
        }
      })
  }
}
