import { Controller } from "@hotwired/stimulus";
import { nativeCallback } from "../helpers/native_callback_helper";

export default class extends Controller {
  static targets = ["toast"];
  isInline = null;

  connect() {
    this.isInline = this.element.classList.contains("toast-inline");

    for (const element of this.element.getElementsByClassName("toast")) {
      const type = element.dataset.type;
      const message = element.dataset.message;

      if (!this.nativeMessage(message, type)) {
        const toast = new bootstrap.Toast(element);
        toast.show();
      }
    }
  }

  nativeMessage(text, type) {
    if (type != "success") return false;

    return nativeCallback("showNotificationMessage", { message: text });
  }
}
