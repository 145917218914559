import { Controller } from "@hotwired/stimulus";
import { debounce } from "../helpers/debounce";

// Connects to data-controller="validate-password-strength"
export default class extends Controller {
  static targets = ["field", "feedback"];

  connect() {
    this.prepareFieldTarget();
    this.addFeedback();
    this.fetchFeedback();
    this.fieldTarget.addEventListener("input", this, false);
  }

  addFeedback() {
    this.fieldTarget.insertAdjacentHTML(
      "afterend",
      `<div class="password-strength" data-${this.identifier}-target="feedback"></div>`
    );
  }

  fetchFeedback() {
    const params = { password: this.fieldTarget.value };
    const urlParams = new URLSearchParams(params).toString();
    var url = `/v1/password_strength?${urlParams}`;

    debounce(() => {
      fetch(url).then((response) => {
        response.json().then((json) => {
          this.replaceFeedback(json);
        });
      });
    })();
  }

  replaceFeedback(json) {
    this.feedbackTarget.innerHTML = json.html;

    var load_height = this.feedbackTarget.firstChild.clientHeight;
    this.feedbackTarget.style.height = load_height + "px";

    this.feedbackTarget.classList.remove("is-valid");
    this.fieldTarget.classList.remove("is-invalid");

    if (this.fieldTarget.value != "") {
      if (json.valid) {
        this.feedbackTarget.classList.add("is-valid");
      } else {
        this.fieldTarget.classList.add("is-invalid");
      }
    } else {
      if (this.fieldTarget.required) {
        this.fieldTarget.classList.add("is-invalid");
      } else {
        this.fieldTarget.classList.remove("is-invalid");
      }
    }
    // Notify parent form a change may have occured
    this.fieldTarget.form.dispatchEvent(new CustomEvent("change"));
  }

  handleEvent(e) {
    switch (e.type) {
      case "input":
        this.fetchFeedback();
        break;
    }
  }

  // private

  prepareFieldTarget() {
    var field = this.element.getElementsByTagName("input")[0];
    field.setAttribute(`data-${this.identifier}-target`, "field");
  }
}
