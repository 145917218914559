// Create Turbo unload event
const dispatchUnloadEvent = function () {
  const event = document.createEvent("Events");
  event.initEvent("turbo:unload", true, false);
  document.dispatchEvent(event);
};
addEventListener("beforeunload", dispatchUnloadEvent);
addEventListener("turbo:before-render", dispatchUnloadEvent);

document.addEventListener("turbo:load", () => {
  if (!document.querySelector("[data-scroll-position]")) return;

  // Restore scroll position
  const scrollPosition = document.querySelector("[data-scroll-position]")
    .dataset["scrollPosition"];
  if (!!scrollPosition && !!localStorage.getItem(scrollPosition)) {
    window.scrollTo(0, localStorage.getItem(scrollPosition));
    localStorage.removeItem(scrollPosition);
  }
});

document.addEventListener("turbo:unload", () => {
  //   storeScrollPosition();
});

const storeScrollPosition = () => {
  if (!document.querySelector("[data-scroll-position]")) return;

  // Store scroll position for pages with data-scroll-position
  const scrollPosition = document.querySelector("[data-scroll-position]")
    .dataset["scrollPosition"];
  if (!!scrollPosition && window.scrollY > 0) {
    localStorage.setItem(scrollPosition, window.scrollY);
  }
};

window.storeScrollPosition = storeScrollPosition;
