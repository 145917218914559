import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (this.data.has('clickable-rows')) {
      this.clickableRows();
    }
  }

  clickableRows() {
    this.element.querySelectorAll("tbody > tr").forEach((tr) => {
      const links = tr.querySelectorAll("a[href]");
      if (links.length > 0) {
        tr.addEventListener("click", function (event) {
          // Only execute if clicked on anything else in table row
          if (
            !["a", "i", "button", "input", "path", "label"].includes(event.target.tagName.toLowerCase())
          ) {
            document.location = links[0].href;
          }
        });
        tr.classList.add("clickable");
      }
    });
  }
}
