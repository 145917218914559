import { Controller } from "@hotwired/stimulus"
import { prettyPrintJson } from "pretty-print-json";

export default class extends Controller {
  connect() {
    const data = this.data.get("json");
    try {
      const json = JSON.parse(data);
      this.element.innerHTML = prettyPrintJson.toHtml(json, {"indent": 2, "quoteKeys": true});
      this.element.classList.add("json-container");
    } catch(e) {
      console.log(`Failed to parse data: ${data}`)
    }
  }
}
