import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["tab"];

  connect() {
    this.initializeTabs();
    this.activateTab();
  }

  initializeTabs() {
    this.tabTargets.forEach((tab) => {
      const tabTrigger = new bootstrap.Tab(tab);
      tab.addEventListener("click", (event) => {
        event.preventDefault();
        tabTrigger.show();
        localStorage.setItem(this.element.id, event.target.id);
      });
    });
  }

  activateTab() {
    var element;
    var lastActiveTab = localStorage.getItem(this.element.id);
    if (!!lastActiveTab) {
      element = this.tabTargets.find((tab) => tab.id == lastActiveTab);
    }
    if (!element) element = this.tabTargets[0];
    bootstrap.Tab.getInstance(element).show();
  }
}
