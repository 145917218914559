debounceTimer = null;

const debounce = (func, timeout = 250) => {
  return (...args) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export { debounce };
