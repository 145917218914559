import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clear-input-field"
export default class extends Controller {
  static targets = ["field", "icon"];

  connect() {
    this.prepareFieldTarget();
    this.addIcon();
    this.setIconVisibility();
    this.fieldTarget.addEventListener("input", this, false);
  }

  addIcon() {
    this.fieldTarget.insertAdjacentHTML(
      "afterend",
      `<i class="fa fa-fw fa-circle-xmark form-control-icon" \
        data-${this.identifier}-target="icon" \
        data-action="click->${this.identifier}#clear"></i>`
    );
  }

  clear() {
    this.fieldTarget.value = "";
    this.fieldTarget.dispatchEvent(new Event("input"));
    this.fieldTarget.focus();
  }

  setIconVisibility() {
    if (!!this.fieldTarget.value) {
      this.iconTarget.classList.remove("d-none");
    } else {
      this.iconTarget.classList.add("d-none");
    }
  }

  handleEvent(e) {
    switch (e.type) {
      case "input":
        this.setIconVisibility();
        break;
    }
  }

  // private

  prepareFieldTarget() {
    var field = this.element.getElementsByTagName("input")[0];
    field.setAttribute(`data-${this.identifier}-target`, "field");
    field.classList.add("with-label");
  }
}
