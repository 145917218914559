// Implements bulk actions on view bulk actions
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "selectionDiv", "selectionCount", "toggleAll" ]
  selection = []

  connect() {
    this.render()
    this.addEventListeners()
  }

  disconnect() {
    this.removeEventListeners()
  }

  toggleAll(e) {
    const checkbox = e.target
    if (checkbox.checked) {
      this.selectAll()
    } else {
      this.selectNone()
    }
  }

  toggle(e) {
    const checkbox = e.target
    if (checkbox.checked) {
      this.selection.push(checkbox.value)
    } else {
      this.removeFromSelection(checkbox.value)
    }
    this.renderSelectionCount()
  }

  removeFromSelection(value) {
    var index = this.selection.indexOf(value);
    if (index > -1) {
      this.selection.splice(index, 1);
    }
  }

  selectAll() {
    this.selection = [];
    this.checkboxTargets.forEach((checkbox) => {
      this.selection.push(checkbox.value);
    });
    this.render();
  }

  selectNone() {
    this.selection = []
    this.render()
  }

  render() {
    this.updateCheckboxes()
    this.renderSelectionCount()
  }

  updateCheckboxes() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = this.selection.includes(checkbox.value)
    })
  }

  renderSelectionCount() {
    if (!this.hasSelectionCountTarget) {
      return
    }

    this.selectionCountTarget.innerHTML = `${this.selection.length} ${this.selectionCountTarget.dataset.label}`;
    if (this.selection.length == 0) {
      // Hide message
      this.selectionDivTarget.classList.add('lighter');
      this.selectionDivTarget.querySelector("button").disabled = true
    } else {
      this.selectionDivTarget.classList.remove('lighter');
      this.selectionDivTarget.querySelector("button").disabled = false
    }
  }

  selectedIds() {
    return this.selection
  }

  reset() {
    this.selection = []
    this.toggleAllTarget.checked = false;
    this.render()
  }

  addEventListeners() {
    addEventListener("bulkActions:render", this, false);
    addEventListener("bulkActions:removeFromSelection", this, false);
  }

  removeEventListeners() {
    removeEventListener("bulkActions:render", this, false);
    removeEventListener("bulkActions:removeFromSelection", this, false);
  }

  handleEvent(e) {
    switch(e.type) {
      case 'bulkActions:render':
        this.render()
        break;
      case 'bulkActions:removeFromSelection':
        this.removeFromSelection(e.detail)
        this.render()
        break
    }
  }
}
